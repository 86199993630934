<template>
  <div class="content clearfix">
    <nf-breadcrumb :breadList="breadList"></nf-breadcrumb>
    <div class="nf-tabs">
      <!-- <tab-nav :tabsNav="tabsNav" @changeTabsNav="switchNav"></tab-nav> -->

      <div class="nf-tabs-nav">
        <router-link
          v-for="(nav, index) in tabsNav"
          :key="index"
          class="nf-tabs-nav-label"
          v-show="index!==4 || isAssign"
          :class="{'is-active': activeNav === index}"
          :to="`${nav.url}`"
        > 
          <div>
            <el-badge :value="backlog" class="item" v-if="nav.name === '待办管理' && backlog != '0'">
              <i v-if="nav.name === '待办管理'" class="icon icon-await"></i>
            </el-badge>
            <i v-if="nav.name === '待办管理' && backlog == '0'" class="icon icon-await"></i>
            <i v-if="nav.name === '办文管理'" class="icon icon-processing"></i>
            <i v-if="nav.name === '拟文管理'" class="icon icon-draft"></i>
            <i v-if="nav.name === '全部公文'" class="icon icon-all"></i>
            <i v-if="nav.name === '指派管理'" class="icon icon-zp"></i>
            <span>{{nav.name}}</span>
           </div>
        </router-link>
      </div>
      <div class="nf-tabs-content">


    <div class="operate">
      <!-- <el-button :style="{color:'#3380FE',border:'1px solid #3380FE'}">删除</el-button> -->
      <el-button type="primary" @click="dialogVisible = true" icon="el-icon-document-add">新增拟文</el-button>
    </div>
    <div v-show="currentTabsNav === 0">
      <el-table
        ref="multipleTable"
        stripe
        :data="tableData"
        tooltip-effect="dark"
        style="width: 100%"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="80"></el-table-column>
        <el-table-column header-align="center" label="公文标题">
          <template slot-scope="scope">
            <span
              @click="toDetails(scope.row.documentStatus, scope.row.id, scope.row.documentType)"
              class="table-cont-title"
            >{{ scope.row.documentTitle }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="documentStatus" label="状态" align="center" width="250">
          <template slot-scope="scope">
            <span>{{ returnStatusName(scope.row.documentStatus) }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="applyDate" label="拟稿时间" align="center" width="250"></el-table-column>
        <el-table-column fixed="right" label="操作" width="120">
          <template slot-scope="scope">
            <el-button @click="deleteRow(scope.row)" type="text" size="small">撤回</el-button>
            <el-button
              @click="editBtn(scope.row.documentStatus, scope.row.id, scope.row.documentType)"
              type="text" size="small" >编辑</el-button>
            <el-button @click="deleteBtn(scope.row)" type="text" size="small" >删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <pagination
        v-if="page.total > 10"
        @pageChange="pageChange"
        :total="page.total"
        :currentPage="page.currentPage"
        :pageSize="page.pageSize"
      ></pagination>
    </div>
    <div v-show="currentTabsNav === 1">
      <el-table
        ref="multipleTable"
        stripe
        :data="tableDatas"
        tooltip-effect="dark"
        style="width: 100%"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="80"></el-table-column>
        <el-table-column header-align="center" label="公文标题">
          <template slot-scope="scope">
            <span
              @click="editBtns(scope.row.documentStatus, scope.row.id, scope.row.documentType)"
              class="table-cont-title"
            >{{ scope.row.documentTitle }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="departmentName" label="流程名称" align="center" width="250"></el-table-column>
        <el-table-column prop="applyDate" label="拟稿时间" align="center" width="250"></el-table-column>
        <el-table-column fixed="right" label="操作" width="250">
          <template slot-scope="scope">
            <el-button
              @click="editBtns(scope.row.documentStatus, scope.row.id, scope.row.documentType)"
              type="text"
              size="small"
            >编辑</el-button>
          </template>
        </el-table-column>
      </el-table>
      <pagination
        v-if="pages.total > 10"
        @pageChange="pageChanges"
        :total="pages.total"
        :currentPage="pages.currentPage"
        :pageSize="pages.pageSize"
      ></pagination>
    </div>

  </div>
    </div>

    <el-dialog title="请选择流程模板" :visible.sync="dialogVisible" width="20%">
      <el-select v-model="value" placeholder="请选择地区" popper-class="popperSelect">
        <el-option v-for="item in optionList" :key="item.value" :label="item.label" :value="item.value"></el-option>
      </el-select>
      <el-select v-model="values" placeholder="请选择流程" popper-class="popperSelect">
        <el-option v-for="item in options" :key="item.code" :label="item.name" :value="item.code"></el-option>
      </el-select>
      <span slot="footer" class="dialog-footer">
        <!-- <el-button @click="dialogVisible = false">取 消</el-button> -->
        <el-button type="primary" @click="dialogVisi">确 定</el-button>
      </span>
    </el-dialog>
    <el-button type="text" @click="isDialog = true"></el-button>
    <el-dialog :title="reason_title + '原因'" :visible.sync="isDialog" width="30%">
      <el-input
        type="textarea"
        :rows="3"
        placeholder="请输入内容"
        v-model="reason_text">
      </el-input>
      <div slot="footer" class="dialog-footer text_center">
        <el-button type="primary" @click="submitBtn">确定{{reason_title}}</el-button>
      </div>
    </el-dialog>
    <el-button type="text" @click="isDialogs = true"></el-button>
    <el-dialog title="删除原因" :visible.sync="isDialogs" width="30%">
      <el-input
        type="textarea"
        :rows="3"
        v-model="reason_text">
      </el-input>
      <div slot="footer" class="dialog-footer text_center">
        <el-button type="primary" @click="isDialogs = false">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<!--popperSelect-->
<script>
import { Table, Select, MessageBox } from 'element-ui';
import pagination from '@/component/pagination/index.vue';
import TabNav from './component/tab-nav';

import nfBreadcrumb from "@/component/breadcrumb/breadcrumb";
export default {
  components: {
    pagination,
    TabNav,
    nfBreadcrumb
  },
  data() {
    return {
      dialogVisible: false,
      currentTabsNav: 0,
      breadList: [
        {
          name: "业务协同"
        },
        {
          name: "移动办公"
        }
      ],
      backlog:'',
      activeNav: 2,
      isAssign: false,
      tabsNav: [
        {
          name: "待办管理",
          url: "awaitDocumentText"
        },
        {
          name: "办文管理",
          url: "documentText"
        },
        {
          name: "拟文管理",
          url: "proposedText"
        },
        {
          name: "全部公文",
          url: "allOfficial"
        },
        {
          name: "指派管理",
          url: "assign"
        }
      ],
      options: [],
      optionList: [
        {
          value: 'HAINAN',
          label: '省南繁管理局'
        },
        {
          value: 'SANYA',
          label: '三亚'
        },
        {
          value: 'LEDONG',
          label: '乐东'
        },
        {
          value: 'LINGSHUI',
          label: '陵水'
        }
      ],
      value: '',
      values: '',
      page: {
        total: 100,
        currentPage: 1,
        pageSize: 10
      },
      pages: {
        total: '',
        currentPage: 1,
        pageSize: 10
      },
      tableData: [],
      tableDatas: [],
      multipleSelection: [],
      params: {
        documentStatus: 1,
        pageNum: 1,
        pageSize: 10
      },
      param: {
        documentStatus: 2,
        pageNum: 1,
        pageSize: 10
      },
      reason_title: '', // 弹框标题
      isDialog: false, // 弹框
      reason_text: '', // 弹框内容
      deleteId: '', // 删除id
      isDialogs: false // 点击详情删除回显
    };
  },
  watch: {
    value(val) {
      this.group(val);
    }
  },
  created() {
    this.getList_one();
    this.getList_two();
    this.getIsAdmin();
    this.getBacklog()
  },
  methods: {
    async getBacklog() {
      const api = this.$fetchApi.queryReceiptList.api;
      const data = await this.$fetchData.fetchPost(this.param, api, 'json');
      if (data.code === '200') {
        this.backlog = data.result.total;
        this.$store.commit('render', this.backlog);
      }
    },
    async getIsAdmin () {
      const api = this.$fetchApi.isAdministrators.api;
      const data = await this.$fetchData.fetchGet({}, api, 'json');
      if (data.code == '200') {
        this.isAssign =   data.result;
      }
    },
    async group(val) {
      const api = this.$fetchApi.queryApprovalChain.api + val;
      const res = await this.$fetchData.fetchPost({ area: val }, api, 'json');
      if (res.code === '200') {
        if (res.result.lenght !== 0) {
          this.options = res.result;
        }
      }
    },
    deleteRow(data) {
      if (data.isHandle) {
        this.$message('这条信息不支持撤回');
        return;
      } else {
        this.$confirm('您确定需要撤回当前文件吗?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(async () => {
          const api = this.$fetchApi.updateReceiptStatus.api + '/' + data.id;
          const res = await this.$fetchData.fetchPost({}, api, 'json');

          if (res.code === '200') {
            this.$message({ type: 'success', message: '该公文已撤回至草稿箱，请到草稿箱查询' });
            this.getList_one()
            this.getList_two()
          } else {
            this.$message({ type: 'error', message: res.message });
          }
        }).catch(() => {
          this.$message({ type: 'info', message: '已取消撤回' });
        });
      }
    },
    async getList_one() {
      const api = this.$fetchApi.queryDispatchList.api;
      const data = await this.$fetchData.fetchPost(this.params, api, 'json');
      if (data.code === '200') {
        this.tableDatas = data.result.rows;
        this.pages.total = data.result.total;
      }
    },
    async getList_two() {
      const api = this.$fetchApi.queryDispatchList.api;
      const data = await this.$fetchData.fetchPost(this.param, api, 'json');
      if (data.code === '200') {
        this.tableData = data.result.rows;
        this.page.total = data.result.total;
      }
    },
    // 根据状态返回名称
    returnStatusName(status) {
      if (status === '1') {
        return '草稿箱';
      } else if (status === '2') {
        return '已发送';
      } else if (status === '3') {
        return '已同意';
      } else if (status === '4') {
        return '已驳回';
      }
    },
    dialogVisi() {
      if (!this.value) {
        return this.$message('选择框不能为空');
      }
      if (!this.values) {
        return this.$message('选择框不能为空');
      }
      this.$router.push({
        path: '/formDetails',
        query: { area: this.value, code: this.values, type: 'handle' }
      });
    },
    // 分页器
    pageChange(item) {
      this.param.pageNum = item.page_index;
      this.param.pageSize = item.page_limit;
      this.getList_two();
    },
    pageChanges(item) {
      this.params.pageNum = item.page_index;
      this.params.pageSize = item.page_limit;
      this.getList_one();
    },
    // 列表全选
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    switchNav(data) {
      this.currentTabsNav = data;
      this.getList_one();
      this.getList_two();
    },
    // 跳转详情
    async toDetails(num, id, code) {
      if (num === '5') {
        const params = {
          documentType: code,
          id: id
        };
        const api = this.$fetchApi.findApplyDetails.api;
        const data = await this.$fetchData.fetchPost(params, api, 'json');
        this.isDialogs = true
        this.reason_text = data.result.documentDetail.deleteReason || '无'
        return
      }
      id = id || 456;
      this.$router.push(
        '/formDetails?id=' + id + '&code=' + code + '&type=proposed'
      );
    },
    // 发文删除按钮
    deleteBtn(item) {
      if(item.documentStatus === '5') {
        this.$message('该条信息不支持删除');
        return false
      }
      this.isDialog = true;
      this.reason_text = '';
      this.reason_title = '删除';
      this.deleteId = item.id;
    },
    // 弹框确定按钮
    async submitBtn() {
      this.isDialog = false;
      const api = this.$fetchApi.updateStatus.api;
      const data = await this.$fetchData.fetchPost({deleteReason: this.reason_text, documentId: this.deleteId}, api, '');
      this.page.currentPage = 1;
      this.getList_two();
    },
    // 拟文列表编辑
    editBtn(num, id, code) {
      if (num !== '4') {
        return this.$message('该呈批单不能编辑');
      } else {
        this.$router.push(
          '/formDetails?id=' + id + '&code=' + code + '&type=proposed'
        );
      }
    },
    // 草稿列表编辑
    editBtns(num, id, code) {
      this.$router.push(
        '/formDetails?id=' + id + '&code=' + code + '&type=draft'
      );
    }
  }
};
</script>
<style lang="less" scoped>
.operate {
  padding: 20px;
}

.table-cont-title {
  cursor: pointer;
}
.el-select {
  margin-bottom: 10px;
}
.text_center {
  text-align: center;
}

.nf-tabs {
  max-width: 1200px;
  min-height: 600px;
  display: flex;
  margin: 0 auto;

  .icon {
    display: inline-block;
    vertical-align: middle;
    width: 30px;
    height: 30px;
    background: none no-repeat center;
    background-size: 100%;
    margin-right: 4px;

    &-await {
      background-image: url("../../assets/images/officialDocument/icon_db.png");
    }

    &-processing {
      background-image: url("../../assets/images/officialDocument/icon_bw.png");
    }

    &-draft {
      background-image: url("../../assets/images/officialDocument/icon_nw.png");
    }
    &-all {
      background-image: url("../../assets/images/officialDocument/icon_all.png");
    }
    &-zp {
      background-image: url("../../assets/images/officialDocument/icon_zp.png");
    }
  }

  &-nav {
    min-width: 170px;
    max-width: 170px;
    margin-right: 10px;
    background: #ffffff;
    box-shadow: 2px 0px 3px 0px rgba(38, 90, 225, 0.15);

    &-label {
      display: block;
      height: 60px;
      line-height: 60px;
      padding: 0 48px 0 24px;
      font-weight: 700;
      color: #3380fe;

      &.is-active {
        background: url("../../assets/images/officialDocument/pic_tab_nav_bg.png")
          center no-repeat;
        background-size: 100%;
      }
    }
  }

  &-content {
    width: 100%;
  }
}
</style>
